:root {
	--headings-font: 'Poppins', sans-serif;
	/* --paragraph-font: 'Roboto Mono', monospace; */
	/* --paragraph-font: 'Fira Code', monospace; */
	--paragraph-font: 'Roboto', sans-serif;
}

[data-theme="day"] {
	--background-primary: #ffffff;
	--background-secondary: #c9c9c9;
	--background-tertiary: #F2F2F2;
	--font-primary: #181818;
	--font-secondary: #555555;
	--color-one: #147ace;
}

[data-theme="night"] {
	--background-primary: #121235;
	--background-secondary: #000000;
	--background-tertiary: #000925;
	--font-primary: #e0e8ff;
	--font-secondary: #C5D4FF;
	--color-one: #00beaf;
}

[data-theme="star"] {
	--background-primary: #202020;
	--background-secondary: #000000;
	--background-tertiary: #000000;
	--font-primary: #ebebeb;
	--font-secondary: #b3b3b3;
	--color-one: #846df7;
}